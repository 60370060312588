<template>
  <b-card no-body>
    <b-card-body class="text-right">
      <b-button
        variant="primary"
        :to="$route.path + '/add'"
      >
        <FeatherIcon icon="PlusIcon" /> Fotoğraf Yükle
      </b-button>
    </b-card-body>
    <b-card-body>
      <b-row>
        <b-col
          v-for="item in dataList"
          :key="item.id"
          cols="6"
          md="3"
        >
          <div class="border p-1 rounded mb-3">
            <b-img
              :src="baseUrl + '/media/gallery/' + item.image"
              fluid
            />
            <div class="font-weight-bold text-center mt-1 border-bottom pb-1 mb-1">
              {{ item.title }}
            </div>
            <div class="d-flex justify-content-between">
              <b-button
                variant="flat-primary"
                size="sm"
                class="btn-icon"
                pill
                :to="$route.path + '/edit/' + item.id"
              >
                <FeatherIcon icon="EditIcon" />
              </b-button>
              <b-button
                variant="flat-danger"
                size="sm"
                class="btn-icon"
                pill
                @click="removeData(item.id)"
              >
                <FeatherIcon icon="TrashIcon" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BPagination, BButton, BCardFooter, BImg, BRow, BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'image_gallery'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BPagination,
    BButton,
    BCardFooter,
    BImg,
    BRow,
    BCol,
  },
  data() {
    return {
      baseUrl: this.$store.state.app.baseURL,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.title AS title`,
          `${tableName}.filename AS image`,
        ],
        where: {
          'image_gallery.filename !=': null,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['imageGallery/dataList']
    },
    dataCounts() {
      return this.$store.getters['imageGallery/dataCounts']
    },
    saveData() {
      return this.$store.getters['imageGallery/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('imageGallery/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('imageGallery/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
